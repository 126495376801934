import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import { getOrders } from "../../services/api";
import AffiliateFooter from "../layout/affiliate-footer";
import AffiliateHeader from "../layout/affiliate-header";
import { useSelector, useDispatch } from 'react-redux';
import * as utils from '../../utils';
import { globalConfig } from "../../GlobalConfig";
import "./Sales.scss"; // CSS

const Sales = () => {
    const dispatch = useDispatch();
    const affiliateData = useSelector(state => state.affiliateAuth.affiliateSessionData);
    const headers = useMemo(() => {
        return { sessionid: affiliateData.sessionId };
    }, [affiliateData.sessionId]);

    const [sortOrder, setSortOrder] = useState(-1);
    const [sortField, setSortField] = useState("createdTs");
    const [sortKey, setSortKey] = useState("-createdTs");

    const [orders, setOrders] = useState([]);
    const toast = useRef();
    const [loading, setLoading] = useState(false);

    const [pageCount] = useState(globalConfig.pageCount);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(pageCount + 1);
    const [last, setLast] = useState(true);
    const [salesData, setSalesData] = useState(true);
    const [paging, setPaging] = useState(false);
    const nVal = null;
    const [purchaseStatus, setPurchaseStatus] = useState([]);
    const [purchaseDate, setPurchaseDate] = useState(nVal);
    const [purchaseDateFrom, setPurchaseDateFrom] = useState(nVal);
    const [purchaseDateTo, setPurchaseDateTo] = useState(nVal);

    const currentDate = new Date();

    const convertToCSV = (data, headers) => {
        const headerRow = headers.map((header) => header.title);
        const csvContent = headerRow.join(',') + '\n';

        const csvRows = data.map((row) =>
            headers
                .map((header) => {
                    const value = row[header.id];
                    return typeof value === 'string' && value.includes(',')
                        ? `"${value}"`
                        : value;
                })
                .join(',')
        );

        return csvContent + csvRows.join('\n');
    };

    const handleExportCSV = useCallback((orderData) => {
        const customHeaders = [
            { id: 'dataPlan', title: 'Name of the plan' },
            { id: 'createdTs', title: 'Purchased On' },
            { id: 'totalOrderAmount', title: 'Price' },
            { id: 'orderStatus', title: 'Order Status' },
        ];
        const csvData = convertToCSV(orderData, customHeaders);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'Sales.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, []);

    const onSortChange = (value) => {
        if (value.indexOf("!") === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        } else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
        setSalesData(true);
    };

    const getAllOrders = useCallback((listingType) => {
        if (salesData) {
            setLoading(true);
            const sortOrderVal = (sortOrder === 1) ? "asc" : "desc";
            const orderStatus = purchaseStatus.join(',');
            const obj = {
                listingType: listingType,
                offset: offset,
                limit: limit,
                sortField: sortField,
                sortOrder: sortOrderVal,
                orderStatus: orderStatus,
                purchaseDateFrom: (purchaseDateFrom !== nVal) ? purchaseDateFrom : '',
                purchaseDateTo: (purchaseDateTo !== nVal) ? purchaseDateTo : '',
            };
            getOrders(headers, dispatch, obj, response => {
                if (response.result === 'SUCCESS') {
                    const list = response.data;
                    const result = list !== nVal ? list.slice(0, pageCount) : [];
                    if (result.length > 0) {
                        setLast(list.length <= pageCount);
                        if (listingType === "export") {
                            list.map(data => {
                                data.createdTs = utils.modifyDateTime(data.createdTs)
                                data.totalOrderAmount = utils.setPrice(data.totalOrderAmount)
                                return nVal
                            })
                            handleExportCSV(list)
                        } else {
                            result.map(data => {
                                data.createdTs = utils.modifyDateTime(data.createdTs)
                                data.totalOrderAmount = utils.setPrice(data.totalOrderAmount)
                                return nVal
                            })
                            setOrders(result);
                            setPaging(true);
                        }
                    } else {
                        setOrders([]);
                        setLast(true);
                        setPaging(false);
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'No records found' });
                    }
                } else {
                    setOrders([]);
                    setLast(true);
                    setPaging(false);
                    const error = response.error;
                    toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
                }
                setLoading(false);
            })
        }
    }, [dispatch, headers, limit, offset, pageCount, purchaseDateFrom, purchaseDateTo, purchaseStatus, sortField, sortOrder, salesData, handleExportCSV])

    useEffect(() => {
        getAllOrders('list');
    }, [getAllOrders]);

    const orderStatus = (rowData) => {
        const status = rowData.orderStatus === 'COMPLETED'
            ? 'completed'
            : rowData.orderStatus === 'PENDING'
                ? 'pending'
                : 'failed'
        return (
            <p>
                <span
                    className={`status ${status}`}
                >{rowData.orderStatus}</span>
            </p>
        );
    };

    const paginate = (currentPage) => {
        setPaging(false);
        setPage(currentPage);
        const offsetVal = (currentPage - 1) * pageCount;
        const limitVal = pageCount + 1;
        setOffset(offsetVal);
        setLimit(limitVal);
        setSalesData(true);
    };

    const itemTemplate = (rowData) => {
        return (
            <div className="col-12 border-0">
                <div className="custom-table-body">
                    <div className="table-grid">
                        <ul className="col-ul">
                            <li className="dataPlanList">{rowData.dataPlan}</li>
                            <li>{rowData.createdTs}</li>
                            <li>{rowData.totalOrderAmount}</li>
                            <li>{orderStatus(rowData)}</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    const handleSelectedDate = (e) => {
        if (e.value === nVal) {
            setPurchaseDate(nVal);
            setPurchaseDateFrom(nVal);
            setPurchaseDateTo(nVal);
        } else {
            const date = e.value;
            const fromDate = utils.modifyDate(date[0]);
            const toDate = (date[1] === nVal) ? fromDate : utils.modifyDate(date[1]);
            setPurchaseDate(date);
            setPurchaseDateFrom(fromDate);
            setPurchaseDateTo(toDate);
        }
        setSalesData(false);
    }

    const handleSelectedStatus = (val) => {
        setPurchaseStatus(val);
        setSalesData(false);
    }

    const purchaseStatusOptions = [
        { label: "Completed", value: "completed" },
        { label: "Pending", value: "pending" },
        { label: "Failed", value: "failed" }
    ];

    const searchSalesFilter = () => {
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
        setSalesData(true);
    }

    const resetSalesFilter = () => {
        setPurchaseDate(nVal);
        setPurchaseDateFrom(nVal);
        setPurchaseDateTo(nVal);
        setPurchaseStatus([]);
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
        setSalesData(true);
    }

    const salesDataHeader = (field, label, selector) => {
        return (
            (sortField !== field) ?
                <>
                    <li onClick={(e) => onSortChange(field)} className={selector}><span>{label}<i className="pi pi-sort-alt"></i></span> </li>
                </> :
                <>
                    {(sortKey === field) ?
                        <>
                            <li onClick={(e) => onSortChange('!' + field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-up-alt"></i></span> </li>
                        </> :
                        <li onClick={(e) => onSortChange(field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-down"></i></span> </li>
                    }
                </>
        );
    }

    return (
        <>
            <div className="main">
                <AffiliateHeader />
                <section className="admin-sales-section">
                    <div className="grid grid-nogutter">
                        <div className="col-12">
                            <div className="heading-sec flex align-items-center justify-content-between">
                                <h2>Bundle Sales</h2>
                                <div className="filter-right">
                                    <PR.Button label="Export CSV" className="exportBtn" icon="pi pi-file-export" iconPos="right" onClick={() => getAllOrders('export')} />
                                    <PR.Calendar value={purchaseDate} onChange={handleSelectedDate} selectionMode="range" readOnlyInput
                                        placeholder="Purchase date range" dateFormat="mm-dd-yy" maxDate={currentDate} showIcon showButtonBar numberOfMonths={2} />
                                    <PR.MultiSelect value={purchaseStatus} filter resetFilterOnHide options={purchaseStatusOptions} onChange={(e) => handleSelectedStatus(e.value)} optionLabel="label" placeholder="Order Status" maxSelectedLabels={1} />
                                    <PR.Button label="Search" className="searchBtn" onClick={searchSalesFilter} />
                                    <PR.Button label="Reset" className="resetBtn" onClick={resetSalesFilter} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sales-data-table card">
                        <div className="card">
                            <div className="custom-table">
                                <div className="custom-table-header">
                                    <div className="table-grid">
                                        <ul className="col-ul sorting-li">
                                            {salesDataHeader('dataPlan', 'Name of the plan', 'dataPlanList')}
                                            {salesDataHeader('createdTs', 'Purchased On', '')}
                                            {salesDataHeader('totalOrderAmount', 'Price', '')}
                                            {salesDataHeader('orderStatus', 'Order Status', '')}
                                        </ul>
                                    </div>
                                </div>
                                <PR.DataView
                                    loading={loading}
                                    value={orders}
                                    itemTemplate={itemTemplate}
                                />
                            </div>
                        </div>

                    </div>
                    {orders.length > 0 ? (
                        <div className="pagination">
                            <button
                                type="button"
                                onClick={() => paginate(page - 1)}
                                className={paging ? page <= 1 ? "disabled" : "pagination-button" : "disabled"}
                            >
                                {globalConfig.pagination_Previous}
                            </button>
                            <span className="active"> {page} </span>
                            <button
                                type="button"
                                onClick={() => paginate(page + 1)}
                                className={paging ? last ? "disabled" : "pagination-button" : "disabled"}
                            >
                                {globalConfig.pagination_Next}
                            </button>
                        </div>
                    ) : (
                        <></>
                    )}
                    <PR.Toast ref={toast} position='top-right' />
                </section>
                <AffiliateFooter />
            </div>
        </>
    );
};

export default Sales;